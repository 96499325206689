import React, { useState, useEffect } from 'react';
import MarqueeComponent from "./marquee.jsx";
import ContactForm from './contact-form.jsx';


const Showdown = () => {
    const data = {
        banner: {
            link: "/assets/img/product-banner/show.jpg",
            linkmobile: "/assets/img/product-banner/show-mobile.jpg"
        },
        marqueeWords: [
            { title: "Carpas Publicitarias " },
            { title: "Banderas Publicitarias" },
            { title: "Mantel Publicitario" },
            { title: "Displays" },
            { title: "Eurofit Banner" },
            { title: "Everyday Banner" },
        ],
        mailerbannerproduct: {
            image: "assets/img/mailer-product-banner/gran-formato.png",
            subtitle: "Dale estructura ",
            highlight: "a tu marca",
            description: `Somos tu mejor opción en estructuras gracias a nuestras alianzas internacionales, que garantizan <strong class="strong">calidad premium y durabilidad.</strong> Nuestras tecnologías de impresión harán que tus ideas brillen. <strong class="strong">Compra en línea.</strong>`,
            extratext: "o contáctanos si tienes algo más específico en mente",
            extrahighlight: ""
        },
        sliderimage: {
            image1: "assets/img/showdown/1.png",
            image2: "assets/img/showdown/2.png",
            image3: "assets/img/showdown/3.png",
            image4: "assets/img/showdown/4.png",
            image5: "assets/img/showdown/5.png",
            link1: "https://www.motistore.com/muro-publicitario",
            link2: "https://www.motistore.com/eurofit",
            link3: "https://www.motistore.com/manteles-publicitarios",
            link4: "https://www.motistore.com/banderas",
            link5: "https://www.motistore.com/carpas-publicitarias",
        },
        faqData: [
            {
                question: "¿Tienen taller?",
                icon: "+"
            },
            {
                question: "¿Ofrecen servicios de instalación?",
                icon: "+"
            },
            {
                question: "¿Ofrecen servicios de diseño gráfico e industrial?",
                icon: "+"
            },
            {
                question: "¿Qué materiales y tecnologías utilizan?",
                icon: "+"
            },
            {
                question: "¿Pueden crear experiencias personalizadas para mi marca?",
                icon: "+"
            }
        ],
        contact: {
            tel: "33 2106 0067",
            email: "jcervantes@motidigital.com"
        },
        options: {
            title: 'Estructuras que potencian tu <strong class="strong">MARCA</strong>',
            head: '',
            1: {
                description: `• <strong class="strong">Banderas Publicitarias:</strong> Estructuras versátiles y llamativas, perfectas para destacar tu marca en eventos o exteriores.
        • <strong class="strong">Manteles Publicitarios:</strong> Soluciones personalizables que convierten cualquier mesa en un espacio promocional.
        • <strong class="strong">Carpas Publicitarias:</strong> Protección y branding en un solo producto, ideales para eventos al aire libre.
        • <strong class="strong">Back de Prensa:</strong> Fondos profesionales para fotografías y eventos, resaltando tu imagen corporativa.
        • <strong class="strong">Eurofit Banner:</strong> Exhibidores modernos con gráficos intercambiables y de fácil transporte.
        • <strong class="strong">Mini Estructuras:</strong> Versiones compactas de banderas y eurofits para escritorios o mostradores.`,
            },
        },

    };


    const images = [
        data.sliderimage.image1,
        data.sliderimage.image2,
        data.sliderimage.image3,
        data.sliderimage.image4,
        data.sliderimage.image5,
    ];

    const [startIndex, setStartIndex] = useState(0);
    const itemsToShow = 3;

    const goToNext = () => {
        setStartIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const goToPrevious = () => {
        setStartIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    // Generar imágenes visibles en bloques de 3
    const getVisibleImages = () => {
        const endIndex = (startIndex + itemsToShow) % images.length;
        if (endIndex > startIndex) {
            return images.slice(startIndex, endIndex);
        } else {
            return [...images.slice(startIndex), ...images.slice(0, endIndex)];
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="gran-formato">
            <section>
                <div>
                    <img className="product-banner pc" src={data.banner.link} alt="Banner de comercio electrónico" />
                    <img className="value-banner-mobile mobile" src={data.banner.linkmobile} alt="Banner de comercio electrónico" />
                </div>
                <MarqueeComponent words={data.marqueeWords} />
            </section>

            <section className="mailerbannerproduct">
                <div className="-product-mailer-content">
                    <img
                        className="product-banner pc"
                        src={data.mailerbannerproduct.image}
                        alt="Banner de comercio electrónico"
                    />
                    <div className="product-text-wraper">
                        <h1 className="product-contact-title">
                            {data.mailerbannerproduct.subtitle}
                            <span className="product-highlight">
                                {data.mailerbannerproduct.highlight}
                            </span>
                        </h1>
                        <img src="assets/img/home-page/ecommerce/dots-moti.png" alt="dots moti" className="dots-moti" />
                        <p
                            className="product-description"
                            dangerouslySetInnerHTML={{ __html: data.mailerbannerproduct.description }}
                        ></p>
                        <h3>
                            {data.mailerbannerproduct.extratext}
                            <strong className='strong'>{data.mailerbannerproduct.extrahighlight}</strong>
                        </h3>
                        <ContactForm />
                    </div>
                </div>
            </section>

            <section className="carousel-section">
                <h2 class="carousel-title">Compra online
                    <img src="assets/img/home-page/ecommerce/logo-dots.png" class="logo+dots" />
                </h2>
                <div className="carousel-container" style={{ display: 'flex', alignItems: 'center' }}>
                    <button className="carousel-arrow left" onClick={goToPrevious}>
                        &#8249;
                    </button>
                    <div className="carousel" style={{ display: 'flex', overflow: 'hidden' }}>
                        {getVisibleImages().map((image, visibleIndex) => {
                            // Calcular el índice real en `images`
                            const realIndex = (startIndex + visibleIndex) % images.length;

                            return (
                                <div
                                    className="img-container-carousel"
                                    key={realIndex}
                                    style={{
                                        flex: '0 0 33.33%',
                                        textAlign: 'center',
                                        opacity: visibleIndex === 1 ? 1 : 0.6,
                                        transform: visibleIndex === 1 ? 'scale(1.1)' : 'scale(1)',
                                    }}
                                >
                                    <a href={data.sliderimage[`link${realIndex + 1}`]} target="blank">
                                        <img
                                            src={image}
                                            alt={`Product ${realIndex}`}
                                            style={{ width: '100%', maxHeight: '19rem' }}
                                        />
                                    </a>
                                </div>
                            );
                        })}
                    </div>
                    <button className="carousel-arrow right" onClick={goToNext}>
                        &#8250;
                    </button>
                </div>
            </section>

            <section className="options-product" style={{ marginBottom: '2rem' }}>
                <h3 style={{ textTransform: 'none' }} dangerouslySetInnerHTML={{ __html: data.options.title }}></h3>
                <img src="assets/img/home-page/ecommerce/dots-moti.png" alt="dots moti" className="dots-moti" />
                <h3 dangerouslySetInnerHTML={{ __html: data.options.head }}></h3>
                <div className="options-container">
                    <div>
                        <p style={{ textTransform: 'none', fontSize: '1.6rem' }} className='options-description' dangerouslySetInnerHTML={{ __html: data.options[1].description }}></p>
                    </div>
                </div>
            </section>
            {/* Sección de Preguntas Frecuentes */}
            {/* <section className="faq-section">
        <div className="faq-wraper">
          <div className="faq-title">
            <h3>Preguntas <span>frecuentes</span></h3>
            <img src="assets/img/home-page/ecommerce/dots-moti.png" alt="dots moti" className="dots-moti" />
          </div>
          <div className="faq-contact">
            <p className="faq-tittle">¿Tienes más preguntas?</p>
            <p className="contact-subtitle">No dudes en contactarnos para más información.</p>
            <div className="contact-info">
              <button className="product-contact-button">
                <span className="product-contact-text">{data.contact.tel}</span>
              </button>
              <div className="product-contact-button">
                <span>{data.contact.email}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="faq-container">
          {data.faqData.map((item, index) => (
            <div key={index} className="faq-item">
              <p>{item.question}</p>
              <span className="faq-icon">{item.icon}</span>
            </div>
          ))}
        </div>
      </section> */}

        </div>
    );
};

export default Showdown;
