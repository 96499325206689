import React, { useState, useEffect } from 'react';
import MarqueeComponent from "./marquee.jsx";
import ContactForm from './contact-form.jsx';
// Componente GranFormato con textos variables
const GranFormato = () => {
  // Datos con los textos variables
  const data = {

    banner: {
      link: "/assets/img/product-banner/gran-formato.jpg",
      linkmobile: "/assets/img/product-banner/gran-formato-mobile.jpg"
    },
    marqueeWords: [
      { title: "Fachadas" },
      { title: "Anuncios luminosos" },
      { title: "Lonas" },
      { title: "Parabuses" },
      { title: "Toldos" },
      { title: "Espectaculares" },
    ],
    mailerbannerproduct: {
      image: "/assets/img/gran-formato/3.png",
      subtitle: " Impresión en Gran Formato",
      highlight: "Tu mensaje a lo grande con nuestra",
      description: `Desde  
    <strong class="strong">el diseño hasta la instalación, </strong> 
    ofrecemos impresión de gran
formato de alta calidad para cualquier superficie. Trabajamos con
marcas Fortune 500, creando soluciones impactantes en <strong class="strong">fachadas, toldos, letreros neón, lonas y más</strong>`,
      extratext: "En Moti Digital lo hacemos posible,",
      extrahighlight: "solicita tu cotización:"
    },
    sliderimage: {
      image1: "/assets/img/gran-formato/1.png",
      image2: "assets/img/gran-formato/2.png",
      /* image3: "assets/img/gran-formato/3.png", se usa en el banner mailer*/
      image4: "assets/img/gran-formato/4.png",
      image5: "assets/img/gran-formato/5.png",
      image6: "assets/img/gran-formato/6.png",
      image7: "assets/img/gran-formato/7.png",
      image8: "assets/img/gran-formato/8.png",
      image9: "assets/img/gran-formato/9.png",
      image10: "assets/img/gran-formato/10.png",
    },
    options: {
      title: 'Innovación y calidad en cada  <strong class="strong">  material</strong>',
      description: {
        title: "",
        description: `
        <h2 class="text-product" style="margin-bottom: 0;">VARIEDAD </h2> <br/>
        • <strong class="strong">Contamos con más de 100 telas, lonas y viniles diferentes,</strong> ofreciendo soluciones innovadoras para punto de venta, materiales publicitarios, branding y diseño.
        
        <h2 class="text-product" style="margin-bottom: 0;">personalización </h2> <br/>
        • Gracias a nuestra impresión de alta calidad y <strong class="strong">combinación ilimitada de colores,</strong>
        garantizamos que la identidad de tu marca se mantenga intacta.
        
        <h2 class="text-product" style="margin-bottom: 0;">sostenibilidad </h2> <br/>
        • Nuestros materiales son ECO-FRIENDLY y tienen un <strong class="strong">ancho máximo de 3.2 metros,</strong>
        adaptándose a tus necesidades sin comprometer la calidad`,
      }
    },
    faqData: [
      {
        question: "¿Ofrecen servicios de instalación?",
        icon: "+"
      },
      {
        question: "¿Tienen opciones de impresión con datos variables?",
        icon: "+"
      },
      {
        question: "¿Ofrecen servicios de diseño?",
        icon: "+"
      },
      {
        question: "¿Los materiales son eco-friendly?",
        icon: "+"
      },
      {
        question: "¿Cuál es el tamaño máximo para impresiones en gran formato?",
        icon: "+"
      }
    ],
    contact: {
      tel: "33 2106 0067",
      email: "jcervantes@motidigital.com"
    },
  };
  const images = [
    data.sliderimage.image1,
    data.sliderimage.image2,
    /* data.sliderimage.image3,  desactvado por que va en el mailer*/
    data.sliderimage.image4,
    data.sliderimage.image5,
    data.sliderimage.image6,
    data.sliderimage.image7,
    data.sliderimage.image8,
    data.sliderimage.image9,
    data.sliderimage.image10,
  ];

  const [startIndex, setStartIndex] = useState(0);
  const itemsToShow = 3;

  const goToNext = () => {
    setStartIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const goToPrevious = () => {
    setStartIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  // Generar imágenes visibles en bloques de 3
  const getVisibleImages = () => {
    const endIndex = (startIndex + itemsToShow) % images.length;
    if (endIndex > startIndex) {
      return images.slice(startIndex, endIndex);
    } else {
      return [...images.slice(startIndex), ...images.slice(0, endIndex)];
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  return (
    <div className="gran-formato">
      {/* Sección de Banner */}
      <section>
        <div >
          <img className="product-banner pc" src={data.banner.link} alt="Banner de comercio electrónico" />
          <img className="value-banner-mobile mobile" src={data.banner.linkmobile} alt="Banner de comercio electrónico" />
        </div>
        <MarqueeComponent words={data.marqueeWords} />
      </section>

      {/* Sección de mailer Banner */}
      <section className="mailerbannerproduct">
        <div className="-product-mailer-content">
          <img
            className="product-banner pc"
            src={data.mailerbannerproduct.image}
            alt="Banner de comercio electrónico"
          />
          <div className="product-text-wraper">
            <h1 className="product-contact-title">
              <span className="product-highlight">
                {data.mailerbannerproduct.highlight}
              </span>
              {data.mailerbannerproduct.subtitle}
            </h1>
            <img src="assets/img/home-page/ecommerce/dots-moti.png" alt="dots moti" className="dots-moti" />
            <p
              className="product-description"
              dangerouslySetInnerHTML={{ __html: data.mailerbannerproduct.description }}
            ></p>
            <h3>
              {data.mailerbannerproduct.extratext}
              <strong className='strong'>{data.mailerbannerproduct.extrahighlight}</strong>
            </h3>
            <ContactForm />
          </div>
        </div>
      </section>

      {/* Sección de slider */}
      <section className="carousel-section">
        <h2 className="carousel-title">Impresión Gran Formato <img src="assets/img/home-page/ecommerce/logo-dots.png" className='logo+dots' /></h2>
        <div className="carousel-container" style={{ display: 'flex', alignItems: 'center' }}>
          <button className="carousel-arrow left" onClick={goToPrevious}>
            &#8249;
          </button>
          <div className="carousel" style={{ display: 'flex', overflow: 'hidden' }}>
            {getVisibleImages().map((image, index) => (
              <div className='img-container-carousel'
                key={index}
                style={{
                  flex: '0 0 33.33%',
                  textAlign: 'center',
                  opacity: index === 1 ? 1 : 0.6,
                  transform: index === 1 ? 'scale(1.1)' : 'scale(1)'
                }}
              >
                <img
                  src={image}
                  alt={`Product ${index}`}
                  style={{ width: '100%', maxHeight: '19rem' }}
                />
              </div>
            ))}
          </div>
          <button className="carousel-arrow right" onClick={goToNext}>
            &#8250;
          </button>
        </div>
      </section>
      {/* Sección de Elección */}
      <section className="options-product">
        <h3 dangerouslySetInnerHTML={{ __html: data.options.title }}></h3>
        <img src="assets/img/home-page/ecommerce/dots-moti.png" alt="dots moti" className="dots-moti" />
        <div className="options-container">
            <div className='options-description-single' dangerouslySetInnerHTML={{ __html: data.options.description.description }}></div>
          

        </div>
      </section>

      {/* Sección de Preguntas Frecuentes */}
      <section className="faq-section">
        <div className="faq-wraper">
          <div className="faq-title">
            <h3>Preguntas <span>frecuentes</span></h3>
            <img src="assets/img/home-page/ecommerce/dots-moti.png" alt="dots moti" className="dots-moti" />
          </div>
          <div className="faq-contact">
            <p className="faq-tittle">¿Tienes más preguntas?</p>
            <p className="contact-subtitle">No dudes en contactarnos para más información.</p>
            <div className="contact-info">
              <button className="product-contact-button">
                <span className="product-contact-text">{data.contact.tel}</span>
              </button>
              <div className="product-contact-button">
                <span>{data.contact.email}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="faq-container">
          {data.faqData.map((item, index) => (
            <div key={index} className="faq-item">
              <p>{item.question}</p>
              <span className="faq-icon">{item.icon}</span>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default GranFormato;
