import React, { useEffect, useState, useRef } from "react";

const Card = ({ type, imgSrc, title, link, highlight, color, index }) => {
  const [isMobile, setIsMobile] = useState(false);
  const cardRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!isMobile || !cardRef.current) return;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("fade-in");
          entry.target.classList.remove("fade-out");
        } else {
          entry.target.classList.remove("fade-in");
          entry.target.classList.add("fade-out");
        }
      });
    }, {
      threshold: 0.1,
    });

    observer.observe(cardRef.current);

    return () => {
      if (cardRef.current) observer.unobserve(cardRef.current);
    };
  }, [isMobile]);

  const mobileImgSrc = imgSrc.replace(".png", "-mobile.png");
  const selectedImgSrc = isMobile ? mobileImgSrc : imgSrc;

  return (
    <a href={link} rel="noopener noreferrer" className="card-link">
      <div
        ref={cardRef}
        className="card fade-out"
        type={type}
        style={{
          "--card-hover-color": color,
          height: isMobile ? "7rem" : `calc(22rem + ${index * 2}rem)`,
        }}
      >
        <img src={selectedImgSrc} alt={title} className="card-image" />
        <span className="product-card-title">
          {title} 
          <span style={{ color: color }} className="card-higligth">
            {highlight}
          </span>
        </span>
      </div>
    </a>
  );
};

const cardsData = [
  {
    imgSrc: 'assets/img/home-page/categories/gran-formato.png',
    title: 'Gran\n ',
    highlight: 'Formato',
    color: 'rgb(68 197 195)',
    link: '/#/gran-formato', 
  },
  {
    imgSrc: 'assets/img/home-page/categories/etiquetas-mangas.png',
    title: 'Etiquetas\n',
    highlight: ' y Mangas',
    color: 'rgb(197 178 167)',
    link: '/#/etiquetas-mangas',
  },
  {
    imgSrc: 'assets/img/home-page/categories/brand-experience.png',
    title: 'Brand\n ',
    highlight: 'Experience',
    color: 'rgb(99 25 50)',
    link: '/#/brand-experience',
  },
  {
    imgSrc: 'assets/img/home-page/categories/packaging-lujo.png',
    title: 'Packaging\n ',
    highlight: 'de Lujo',
    color: 'rgb(248, 141, 34)',
    link: '/#/packaging-lujo',
  },
  {
    imgSrc: 'assets/img/home-page/categories/displays-visual-merch.png',
    title: 'Displays y\n ',
    highlight: 'Visual Merch',
    color: 'rgb(203 14 14)',
   link: '/#/displays-visual-merch', 
  },
  {
    imgSrc: 'assets/img/home-page/categories/show.png',
    title: 'Estructuras y\n ',
    highlight: 'displays',
    color: 'rgb(68 197 195)',
   link: '/#/showdown', 
  },
];

const ProductSection = () => {
  return (
    <div className="product-container" id="products">
      <div className="product-title">
        <span>Productos y servicios</span>
      </div>
      <div className="product-text">
        <span className="product-text">Vive la experiencia Moti</span>
      </div>
      <div className="product-links">
        {cardsData.map((data, index) => (
          <Card key={index} {...data} index={index} />
        ))}
      </div>
    </div>
  );
};

export default ProductSection;