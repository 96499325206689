import React from 'react';
import UsBanner from './components/UsBanner';
import Numeros from './components/Nuestros_numeros';
import Isobanner from './components/Isobanner';
import ProyectosRecientes from './components/Projects';
import Slider from './components/UsSlider';

const Nosotros = () => {
  const textos = {
    titulo: {
      pregunta: '¿Quiénes ',
      respuesta: 'somos?'
    },
    subtitulo: {
      introduccion: 'TU MEJOR opción en ',
      enfasis: 'impresión '
    }
  };

  return (
    <div>
      <UsBanner textos={textos} />
      <Numeros/>
      <Isobanner/>
      <ProyectosRecientes/>
      <Slider/>
    </div>
  );
};

export default Nosotros;