import React from "react";

const footerColumns = [
  {
    title: "Nosotros",
    items: [
      { text: "Misión" },
      { text: "Propósito" },
      { text: "Principios" },
      { text: "Rush" },
      { text: "Pasión" },
      { text: "Jugando en equipo" },
      { text: "Alegría" },
      { text: "Adaptabilidad" },
    ],
  },
  
  {
    title: "Contacto",
    items: [
      {
        image: "marker.png",
        text: "Calle 1 No. 1375-5\nZona Industrial C.P. 44960\nGuadalajara, Jalisco, México",
        link: "https://maps.app.goo.gl/YHQqYYvQuibePTUf6",
      },
      { image: "phone.png", text: " Teléfono: 33 3811 7143", link: "tel:+3338117143" },
      { image: "mail.png", text: " Email: contacto@motidigital.com", link: "mailto:contacto@motidigital.com" },
      { image: "globe.png", text: " Web: motidigital.com", link: "https://motidigital.com" },
      { image: "privacy-policy.png", text: " Aviso de privacidad", link: "/#/AvisoPrivacidad" },

    ],
  },
  {
    title: "Social Media",
    items: [
      { image: "facebook.png", link: "https://facebook.com/motidigital/" },
      { image: "pinterest.png", link: "https://www.pinterest.com/motidigital/" },
      { image: "instagram.png", link: "https://www.instagram.com/motidigital/" },
    ],
    extraLinks: {
      title: "Material de ventas",
      links: [
        { image: "ventas-ico.png",  href: "http://gofile.me/2LQdY/DIasKH2QJ", text: "Accede aquí" },
      ],
    },
  },
];

const Footer = () => {
  return (
    <footer className="footer relative" id="Footer">
      {footerColumns.map((column, index) => (
        <div className="footer-column" key={index}>
          <div className="title-wrap">
            <h3 className="footer-title">{column.title}</h3>
            <div className="footer-title-divider"></div>
          </div>
          <ul className="footer-list">
            {column.items.map((item, itemIndex) => (
              <li className="footer-list-item" key={itemIndex}>
                {item.image ? (
                  <>
                    <a href={item.link} target="_blank" rel="noopener noreferrer">
                      <img
                        src={`./assets/img/footer/${item.image}`}
                        alt={item.text || column.title}
                        className="local-image"
                      />
                    </a>
                    {item.text && item.text.split('\n').map((line, lineIndex) => (
                      <a href={item.link} target="_blank" rel="noopener noreferrer" key={lineIndex}>
                        <span>
                          {line}
                          {lineIndex < item.text.split('\n').length - 1 && <br />}
                        </span>
                      </a>
                    ))}
                  </>
                ) : (
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    <span>{item.text}</span>
                  </a>
                )}
              </li>
            ))}
          </ul>
          {column.extraLinks && (
            <div className="extra-links">
              <div className="title-wrap">
                <h3 className="footer-title">{column.extraLinks.title}</h3>
                <div className="footer-title-divider"></div>
              </div>
              <div className="links-wrapper">
                {column.extraLinks.links.map((link, extraIndex) => (
                  <a href={link.href} key={extraIndex} target="_blank">
                     <img
                        src={`./assets/img/footer/${link.image}`}
                        className="local-image"
                      />
                    <span>{link.text}</span>
                  </a>
                ))}
              </div>
            </div>
          )}
        </div>
      ))}
    </footer>
  );
};

export default Footer;
