import React, { useState, useEffect } from 'react';
import MarqueeComponent from "./marquee.jsx";
import ContactForm from './contact-form.jsx';


const Packaging = () => {
  const data = {
    banner: {
      link: "/assets/img/product-banner/pack.jpg",
      linkmobile: "/assets/img/product-banner/pack-mobile.jpg"
    },
    marqueeWords: [
      { title: "Tiempos de Entrega Rápidos" },
      { title: "Materiales de Alta Calidad" },
      { title: "Personalización Completa de Diseño" },
      { title: "Sin Mínimos de Producción" },
      { title: "Servicios de Diseño Creativo" },
      { title: "Acabados y Terminados de lujo" },
    ],
    mailerbannerproduct: {
      image: "/assets/img/mailer-product-banner/pack.png",
      subtitle: " Empaques de lujo",
      highlight: " Piensa fuera de la caja con nuestros",
      description: `Con nuestros materiales, diseños personalizados y acabados de lujo,  
      <strong class="strong">las posibilidades son infinitas.</strong> 
      Creamos empaques que transforman el "unboxing" en una experiencia visual memorable.`,
      extratext: "En Moti Digital lo hacemos posible,",
      extrahighlight: "solicita tu cotización:"
    },
    sliderimage: {
      image1: "assets/img/pack/Caja1.png",
      image2: "assets/img/pack/Caja2.png",
      image3: "assets/img/pack/Caja3.png",
    },
    faqData: [
      {
        question: "¿Tienen un pedido mínimo?",
        icon: "+"
      },
      {
        question: "¿Entre qué acabados puedo elegir?",
        icon: "+"
      },
      {
        question: "¿Ofrecen servicios de diseño?",
        icon: "+"
      },
      {
        question: "¿Puedo personalizar mis diseños?",
        icon: "+"
      },
      {
        question: "¿Cuáles son los materiales disponiblespara mis productos?",
        icon: "+"
      }
    ],
    contact: {
      tel: "33 2106 0067",
      email: "jcervantes@motidigital.com"
    },
    options: {
      title: "Nuestros acabados",
      etiquetas: {
        title: "Foil",
        description: "• Crea un acabado táctil elevado y texturizado sobre la superficie del material.\n• Proporciona un efecto brillante y sutil en  elementos específicos, como logotipos o textos importantes.",
      },
      mangas: {
        title: "Barniz",
        description: "•Acabado plano y metálico que ofrece un efecto visual brillante.\n• Perfecto para crear detalles llamativos como acentos decorativos en Plata, dorado,cobre, y otros colores metálicos",
      },
    },

  };
  

  const images = [
    data.sliderimage.image1,
    data.sliderimage.image2,
    data.sliderimage.image3,
  ];

  const [startIndex, setStartIndex] = useState(0);
  const itemsToShow = 3;

  const goToNext = () => {
    setStartIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const goToPrevious = () => {
    setStartIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  // Generar imágenes visibles en bloques de 3
  const getVisibleImages = () => {
    const endIndex = (startIndex + itemsToShow) % images.length;
    if (endIndex > startIndex) {
      return images.slice(startIndex, endIndex);
    } else {
      return [...images.slice(startIndex), ...images.slice(0, endIndex)];
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="gran-formato">
      <section>
        <div>
          <img className="product-banner pc" src={data.banner.link} alt="Banner de comercio electrónico" />
          <img className="value-banner-mobile mobile" src={data.banner.linkmobile} alt="Banner de comercio electrónico" />
        </div>
        <MarqueeComponent words={data.marqueeWords} />
      </section>

      <section className="mailerbannerproduct">
        <div className="-product-mailer-content">
          <img
            className="product-banner pc"
            src={data.mailerbannerproduct.image}
            alt="Banner de comercio electrónico"
          />
          <div className="product-text-wraper">
            <h1 className="product-contact-title">
              <span className="product-highlight">
                {data.mailerbannerproduct.highlight}
              </span>
              {data.mailerbannerproduct.subtitle}
            </h1>
            <img src="assets/img/home-page/ecommerce/dots-moti.png" alt="dots moti" className="dots-moti" />
            <p
              className="product-description"
              dangerouslySetInnerHTML={{ __html: data.mailerbannerproduct.description }}
            ></p>
            <h3>
              {data.mailerbannerproduct.extratext}
              <strong className='strong'>{data.mailerbannerproduct.extrahighlight}</strong>
            </h3>
            <ContactForm />
          </div>
        </div>
      </section>

      <section className="carousel-section">
        <h2 class="carousel-title">Empaques de Lujo
          <img src="assets/img/home-page/ecommerce/logo-dots.png" class="logo+dots" />
        </h2>
        <div className="carousel-container" style={{ display: 'flex', alignItems: 'center' }}>
          <button className="carousel-arrow left" onClick={goToPrevious}>
            &#8249;
          </button>
          <div className="carousel" style={{ display: 'flex', overflow: 'hidden' }}>
            {getVisibleImages().map((image, index) => (
              <div className='img-container-carousel'
                key={index}
                style={{
                  flex: '0 0 33.33%',
                  textAlign: 'center',
                  opacity: index === 1 ? 1 : 0.6,
                  transform: index === 1 ? 'scale(1.1)' : 'scale(1)'
                }}
              >
                <img
                  src={image}
                  alt={`Product ${index}`}
                  style={{ width: '100%', maxHeight: '19rem' }}
                />
              </div>
            ))}
          </div>
          <button className="carousel-arrow right" onClick={goToNext}>
            &#8250;
          </button>
        </div>
      </section>

      <section className="options-product">
        <h3>{data.options.title}</h3>
        <img src="assets/img/home-page/ecommerce/dots-moti.png" alt="dots moti" className="dots-moti" />
        <div className="options-container">
          <div className="option w-30">
            <h4>{data.options.etiquetas.title}</h4>
            <p className='options-description'>{data.options.etiquetas.description}</p>
          </div>
          <div className="option">
            <h4>vs</h4>
          </div>
          <div className="option w-30">
            <h4>{data.options.mangas.title}</h4>
            <p className='options-description'>{data.options.mangas.description}</p>
          </div>
        </div>
      </section>
      {/* Sección de Preguntas Frecuentes */}
      <section className="faq-section">
        <div className="faq-wraper">
          <div className="faq-title">
            <h3>Preguntas <span>frecuentes</span></h3>
            <img src="assets/img/home-page/ecommerce/dots-moti.png" alt="dots moti" className="dots-moti" />
          </div>
          <div className="faq-contact">
            <p className="faq-tittle">¿Tienes más preguntas?</p>
            <p className="contact-subtitle">No dudes en contactarnos para más información.</p>
            <div className="contact-info">
              <button className="product-contact-button">
                <span className="product-contact-text">{data.contact.tel}</span>
              </button>
              <div className="product-contact-button">
                <span>{data.contact.email}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="faq-container">
          {data.faqData.map((item, index) => (
            <div key={index} className="faq-item">
              <p>{item.question}</p>
              <span className="faq-icon">{item.icon}</span>
            </div>
          ))}
        </div>
      </section>

    </div>
  );
};

export default Packaging;
