import React from 'react';

const UsBanner = ({ textos }) => {
  return (
    <div className="nosotros">
      <div className='us-head-text'>
        <img src="../assets/img/home-page/ecommerce/dots-moti.png" alt="Dots de diseño" class="dots-mailer" />
        <div className='us-text-container'>
          <div className='us-wrapper-text'>
            <h1 className='us-banner-tittle'>{textos.titulo.pregunta}</h1>
            <h1 className='us-banner-tittle-special'>{textos.titulo.respuesta}</h1>
          </div>
        </div>
      </div>
      <div className="nosotros-up"></div>
      <div className="nosotros-banner">
        <div className="nosotros-banner-left"></div>
        <div className="nosotros-banner-right"></div>
      </div>
      <div className='us-wrapper-text down-banner'>
        <h1 className='us-banner-tittle up-animated'>{textos.subtitulo.introduccion}</h1>
        <h1 className='us-banner-tittle-special zIndex up-animated'>{textos.subtitulo.enfasis}</h1>
      </div>
    </div>
  );
};

export default UsBanner;