
  import React from 'react';
  import Slider from 'react-slick';
  import 'slick-carousel/slick/slick.css';
  import 'slick-carousel/slick/slick-theme.css';
  
  const BannerSlider = () => {
    const banners = [
      '../assets/img/us/b1.png',
    '../assets/img/us/b2.png',
    '../assets/img/us/b3.png',
    '../assets/img/us/b4.png',
    ];
  
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      nextArrow: <NextArrow />,
    }
  
    return (
      <div className="slider-container custom-slider">
        <Slider {...settings}>
          {banners.map((banner, index) => (
            <div key={index} className="slider-item">
              <img src={banner} alt={`Banner ${index + 1}`} className="slider-image" />
            </div>
          ))}
        </Slider>
      </div>
    );
  };
  
  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={`${className} custom-next-arrow`} onClick={onClick}>
        ➔
      </div>
    );
  };
  
  export default BannerSlider;
  