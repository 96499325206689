// MarqueeComponent.js
import React from "react";

const MarqueeComponent = ({ words }) => {
  return (
    <div className="marquee-wrapper">
      <div className="marquee">
        <div className="marquee-content">
          <span style={{ display: "inline-block", width: "30rem" }}></span>
          {words.map((word, index) => (
            <span key={index} style={{ marginLeft: "4rem" }}>
              {word.title}
              {index !== words.length - 1 && "  "}
            </span>
          ))}
          <span style={{ display: "inline-block", width: "30rem" }}></span>
          {words.map((word, index) => (
            <span key={index + words.length} style={{ marginLeft: "4rem" }}>
              {word.title}
              {index !== words.length - 1 && "  "}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MarqueeComponent;
