import React, { useState, useEffect } from 'react';
import MarqueeComponent from "./marquee.jsx";
import ContactForm from './contact-form.jsx';

const Etiquetas = () => {
  const data = {
    banner: {
      link: "/assets/img/product-banner/etiquetas.jpg",
      linkmobile: "/assets/img/product-banner/etiquetas-mobile.jpg"
    },
    marqueeWords: [
      { title: "BOPP BLANCO" },
      { title: "BOPP TRANSPARENTE" },
      { title: "BOPP METALIZADO" },
      { title: "SEMIGLOSS" },
      { title: "TEXTURIZADO DE LUJO" },
      { title: "MANGAS TERMOENCOGIBLES" },
    ],
    mailerbannerproduct: {
      image: "/assets/img/mailer-product-banner/etiquetas.png",
      subtitle: "¡Etiquetas y Mangas,",
      highlight: " otro rollo!",
      description: `Con nuestros materiales, tamaños, formas y terminados, 
      <strong class="strong">las posibilidades son infinitas.</strong> 
      Creamos etiquetas y mangas que transforman cualquier producto en una experiencia visual única.`,
      extratext: "En Moti Digital lo hacemos posible,",
      extrahighlight: "solicita tu cotización:"
    },
    sliderimage: {
      image1: "assets/img/labels/2.png",
      image2: "assets/img/labels/3.png",
      image3: "assets/img/labels/4.png",
      image4: "assets/img/labels/5.png",
      image5: "assets/img/labels/6.png",
      image6: "assets/img/labels/7.png",
    },
    options: {
      title: "¿Cuál elegir?",
      etiquetas: {
        title: "Etiquetas",
        description: "• IDEALES PARA SUPERFICIES PLANAS O CURVAS.\n• SE APLICAN DIRECTAMENTE AL PRODUCTO.\n• PRESENTACIÓN EN ROLLO O PLANILLA.",
      },
      mangas: {
        title: "Mangas",
        description: "• ENVOLVENTES, CUBREN TODO EL PRODUCTO (360°).\n• OFRECEN UN DISEÑO MÁS LLAMATIVO Y PERSONALIZABLE.\n• REQUIEREN DE CALOR PARA COLOCARSE.",
      },
    },
  };

  const images = [
    data.sliderimage.image1,
    data.sliderimage.image2,
    data.sliderimage.image3,
    data.sliderimage.image4,
    data.sliderimage.image5,
    data.sliderimage.image6,
  ];

  const [startIndex, setStartIndex] = useState(0);
  const itemsToShow = 3;

  const goToNext = () => {
    setStartIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const goToPrevious = () => {
    setStartIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  // Generar imágenes visibles en bloques de 3
  const getVisibleImages = () => {
    const endIndex = (startIndex + itemsToShow) % images.length;
    if (endIndex > startIndex) {
      return images.slice(startIndex, endIndex);
    } else {
      return [...images.slice(startIndex), ...images.slice(0, endIndex)];
    }
  };
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="gran-formato">
      <section>
        <div>
          <img className="product-banner pc" src={data.banner.link} alt="Banner de comercio electrónico" />
          <img className="value-banner-mobile mobile" src={data.banner.linkmobile} alt="Banner de comercio electrónico" />
        </div>
        <MarqueeComponent words={data.marqueeWords} />
      </section>

      <section className="mailerbannerproduct">
        <div className="-product-mailer-content">
          <img
            className="product-banner pc"
            src={data.mailerbannerproduct.image}
            alt="Banner de comercio electrónico"
          />
          <div className="product-text-wraper">
            <h1 className="product-contact-title">
              {data.mailerbannerproduct.subtitle}
              <span className="product-highlight">
                {data.mailerbannerproduct.highlight}
              </span>
            </h1>
            <img src="assets/img/home-page/ecommerce/dots-moti.png" alt="dots moti" className="dots-moti" />
            <p
              className="product-description"
              dangerouslySetInnerHTML={{ __html: data.mailerbannerproduct.description }}
            ></p>
            <h3>
              {data.mailerbannerproduct.extratext}
              <strong className='strong'>{data.mailerbannerproduct.extrahighlight}</strong>
            </h3>
            <ContactForm />
          </div>
        </div>
      </section>

      <section className="carousel-section">
      <h2 class="carousel-title">Etiquetas y mangas 
      <img src="assets/img/home-page/ecommerce/logo-dots.png" class="logo+dots" />
        </h2>
        <div className="carousel-container" style={{ display: 'flex', alignItems: 'center' }}>
          <button className="carousel-arrow left" onClick={goToPrevious}>
            &#8249;
          </button>
          <div className="carousel" style={{ display: 'flex', overflow: 'hidden'}}>
            {getVisibleImages().map((image, index) => (
              <div className='img-container-carousel'
                key={index}
                style={{
                  flex: '0 0 33.33%',
                  textAlign: 'center',
                  opacity: index === 1 ? 1 : 0.6,
                  transform: index === 1 ? 'scale(1.1)' : 'scale(1)'
                }}
              >
                <img
                  src={image}
                  alt={`Product ${index}`}
                  style={{ width: '100%', maxHeight: '19rem' }}
                />
              </div>
            ))}
          </div>
          <button className="carousel-arrow right" onClick={goToNext}>
            &#8250;
          </button>
        </div>
      </section>

      <section className="options-product">
        <h3>{data.options.title}</h3>
        <img src="assets/img/home-page/ecommerce/dots-moti.png" alt="dots moti" className="dots-moti" />
        <div className="options-container">
          <div className="option w-30">
            <h4>{data.options.etiquetas.title}</h4>
            <p className='options-description'>{data.options.etiquetas.description}</p>
          </div>
          <div className="option">
            <h4>vs</h4>
          </div>
          <div className="option w-30">
            <h4>{data.options.mangas.title}</h4>
            <p className='options-description'>{data.options.mangas.description}</p>
          </div>
        </div>
      </section>
      {/* Sección de Preguntas Frecuentes */}
      <section className="faq-section">
        <div className='faq-wraper'>
          <div className="faq-title">
            <h3>Preguntas <span>frecuentes</span></h3>
            <img src="assets/img/home-page/ecommerce/dots-moti.png" alt="dots moti" className="dots-moti" />

          </div>
          <div className="faq-contact">
            <p className='faq-tittle'>¿Tienes más preguntas?</p>
            <p className='contact-subtitle'>No dudes en contactarnos para más información.</p>
            <div className="contact-info">
              <button className="product-contact-button" >
                <span className="product-contact-text">33 1165 3322</span>
              </button>
              <div className="product-contact-button">
                <span>VMUNOZ@MOTIDIGITAL.COM</span>
              </div>
            </div>
          </div>
        </div>

        <div className="faq-container">
          <div className="faq-item">
            <p>¿Qué materiales utilizan para etiquetas?</p>
            <span className="faq-icon">+</span>
          </div>
          <div className="faq-item">
            <p>¿Entre qué acabados puedo elegir?</p>
            <span className="faq-icon">+</span>
          </div>
          <div className="faq-item">
            <p>¿Cuál es la diferencia entre rollo y planilla?</p>
            <span className="faq-icon">+</span>
          </div>
          <div className="faq-item">
            <p>¿Tienen mínimos de compra?</p>
            <span className="faq-icon">+</span>
          </div>
          <div className="faq-item">
            <p>¿Cuentan con servicio de diseño?</p>
            <span className="faq-icon">+</span>
          </div>
        </div>


      </section>
    </div>
  );
};

export default Etiquetas;
