import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const ContactForm = () => {
  const [contact, setContact] = useState({ mail: '' });
  const [showMessage, setShowMessage] = useState(false);

  const YOUR_SERVICE_ID = 'service_lxg5sht'; // reemplaza con tu servicio ID de EmailJS
  const YOUR_TEMPLATE_ID = 'template_epehkcc'; // reemplaza con tu template ID de EmailJS
  const YOUR_USER_ID = '4f0iRLKGvmXsd0ASA'; // reemplaza con tu public key de EmailJS

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContact({ ...contact, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(contact.mail)) {
      alert('Por favor, ingresa un correo válido.');
      return;
    }


    const templateParams = {
      to_name: 'equipo Moti',
      from_name: contact.mail,
      message: `Me interesan sus productos. Me pueden contactar al correo ${contact.mail}, me estoy contactando desde motidigital.com`,
    };

    emailjs
      .send(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, templateParams, YOUR_USER_ID)
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          setContact({ mail: '' });
          setShowMessage(true);
        },
        (err) => {
          console.log('FAILED...', err);
        }
      );
  };

  return (
    <>
      {showMessage ? (
        <div className="alert alert-success" role="alert">
          ¡Email enviado con éxito!
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="contact-form rigth">
          <input
            type="email"
            placeholder="Inserta tu correo aquí"
            value={contact.mail}
            name="mail"
            onChange={handleChange}
            className="contact-input"
          />
          <button type="submit" className="contact-button">
            Empecemos
          </button>
        </form>
      )}
    </>
  );
};

export default ContactForm;
