import Navbar from './Components/1_Navbar';
import HomeBanner from './Components/2_HomeBanner';
import Countbanner from './Components/4_CountBanner';
import ProductSection from './Components/5_ProductSection';
import TechSection from './Components/6_TechSection';
import EcommerceBanner from './Components/7_Ecommerce';
import UsSection from './Components/8_Us';
import Footer from './Components/9_Footer';
import Etiquetas from './Components/Subcategorie/2_etiquetas-mangas';
import Brand from './Components/Subcategorie/3_brand-experience';
import Labels from './Components/Subcategorie/5_displays';
import Packaging from './Components/Subcategorie/4_packaging';
import Mailer from './Components/10_Mailer';
import Testimonies from './Components/12_testimonies';
import Trusted from './Components/13_Trusted';
import ResentProjects from './Components/14_ResentProjects';
import Gif from './Components/15_Gif_area';
import Display from './Components/Subcategorie/5_displays';
import PrivacyPolicy from './Components/PrivacyPolicy/PrivacyPolicy';
import { Routes, Route, HashRouter } from 'react-router-dom';
import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Nosotros from './Components/Us-page/UsPage';
import VideoBanner from './Components/video_banner';
import GranFormato from './Components/Subcategorie/1_gran-formato';
import Showdown from './Components/Subcategorie/6_showdown';


function App() {
  return (
    <div>
      <HashRouter>
        <Navbar />
        <Main />
        <Footer />
      </HashRouter>
    </div>
  );
}

const Main = () => (
  <main id="scrollWrapper" className="main">
    <Routes>
      <Route path="/index.html" element={<HomePage />} />
      <Route path="gran-formato" element={<GranFormato />} />
      <Route path="etiquetas-mangas" element={<Etiquetas />} />
      <Route path="brand-experience" element={<Brand />} />
      <Route path='displays-visual-merch' element={<Display/>}/>
      <Route path="labels" element={<Labels />} />
      <Route path="packaging-lujo" element={<Packaging />} />
      <Route path="showdown" element={<Showdown/>} />
      <Route path="AvisoPrivacidad" element={<PrivacyPolicy />} />
      <Route path='Us' element={<Nosotros/>}/>
      <Route path="/" element={<HomePage />} />
      <Route path="*" element={<HomePage />} />
    </Routes>
  </main>
);

const HomePage = () => {
  return (
    <div>
      <HomeBanner />
      {/* <Infobanner /> */} {/* antigua seccion se deja para futuras secciones */}
      <ProductSection />
      <VideoBanner/>
      <Mailer />
      <TechSection />
      <Countbanner />
      <Trusted/>
      <Testimonies/>
      {/* <BannerApp/> */}
      <EcommerceBanner />
      <ResentProjects />
      <UsSection />
      <Gif />
    </div>
  );
};

export default App;
