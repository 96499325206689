import React from 'react';

const ProyectosRecientes = () => {
  const proyectos = [
    { nombre: 'Hendrik’s', imagen: '../assets/img/us/hendricks.jpg' },
    { nombre: 'Liverpool', imagen: '../assets/img/us/liverpool.jpg' },
    { nombre: 'Pandora', imagen: '../assets/img/us/pandora.jpg' },
  ];

  return (
    <div className="proyectos-recientes">
      <h2 className="proyectos-titulo">Conoce algunos de nuestros proyectos más recientes</h2>
      <div className="proyectos-contenedor">
        {proyectos.map((proyecto, index) => (
          <div className="proyecto-tarjeta" key={index}>
            <img className="proyecto-imagen" src={proyecto.imagen} alt={proyecto.nombre} />
            <div className="proyecto-detalle">
              <span className="proyecto-nombre">{proyecto.nombre}</span>
              <span className="proyecto-flecha">
                <span className="flecha-icono">➔</span>
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProyectosRecientes;
