import React, { useState, useEffect } from 'react';
import MarqueeComponent from "./marquee.jsx";
import ContactForm from './contact-form.jsx';


const Brand = () => {
  const data = {
    banner: {
      link: "/assets/img/product-banner/brand.jpg",
      linkmobile: "/assets/img/product-banner/brand-mobile.jpg"
    },
    marqueeWords: [
      { title: "Escaparates " },
      { title: "Material para Activaciones" },
      { title: "Decoración para Eventos" },
      { title: "Pop-ups" },
      { title: "Custom branding" },
      { title: "Retail interiors" },
    ],
    mailerbannerproduct: {
      image: "assets/img/brand/7.png",
      subtitle: "Escaparates ",
      highlight: "que jamás se escaparán de tu mente",
      description: `Transformamos cada espacio en una extensión de tu
      marca. Con  
      <strong class="strong">escaparates impactantes,</strong> 
        nos aseguramos de crear experiencias que conecten
        emocionalmente con tus clientes.`,
      extratext: "En Moti Digital lo hacemos posible,",
      extrahighlight: "solicita tu cotización:"
    },
    sliderimage: {
      image1: "assets/img/brand/1.png",
      image2: "assets/img/brand/2.png",
      image3: "assets/img/brand/3.png",
      image4: "assets/img/brand/4.png",
      image5: "assets/img/brand/5.png",
      image5: "assets/img/brand/6.png",
      image7: "assets/img/brand/7.png",
      image8: "assets/img/brand/8.png",
    },
    faqData: [
      {
        question: "¿Tienen taller?",
        icon: "+"
      },
      {
        question: "¿Ofrecen servicios de instalación?",
        icon: "+"
      },
      {
        question: "¿Ofrecen servicios de diseño gráfico e industrial?",
        icon: "+"
      },
      {
        question: "¿Qué materiales y tecnologías utilizan?",
        icon: "+"
      },
      {
        question: "¿Pueden crear experiencias personalizadas para mi marca?",
        icon: "+"
      }
    ],
    contact: {
      tel: "33 2106 0067",
      email: "jcervantes@motidigital.com"
    },
    options: {
      title: 'Servicios que potencian tu <strong class="strong">BRAND EXPERIENCE</strong>',
      head:'',
      1: {
        description: "• <strong class=\"strong\">Diseño Gráfico:</strong> Diseño creativo que refleja la esencia de tu marca.\n• <strong class=\"strong\">Preprensa:</strong> Preparación técnica para garantizar resultados impecables.\n• <strong class=\"strong\">Diseño Industrial:</strong> Creación de estructuras y elementos innovadores para experiencias únicas.\n• <strong class=\"strong\">Taller Integral:</strong> Equipado con pintura, soldadura, carpintería, laminado, electricidad, y más.\n• <strong class=\"strong\">Servicio de Instalación:</strong> Montaje profesional para asegurar un impacto visual inmediato.\n• <strong class=\"strong\">Logística Propia:</strong> Entregas eficientes y seguras, desde nuestra planta hasta tu punto de activación.",
    },
    },

  };
  

  const images = [
    data.sliderimage.image1,
    data.sliderimage.image2,
    data.sliderimage.image3,
    data.sliderimage.image4,
    data.sliderimage.image5,
    data.sliderimage.image7,
    data.sliderimage.image8,
  ];

  const [startIndex, setStartIndex] = useState(0);
  const itemsToShow = 3;

  const goToNext = () => {
    setStartIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const goToPrevious = () => {
    setStartIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  // Generar imágenes visibles en bloques de 3
  const getVisibleImages = () => {
    const endIndex = (startIndex + itemsToShow) % images.length;
    if (endIndex > startIndex) {
      return images.slice(startIndex, endIndex);
    } else {
      return [...images.slice(startIndex), ...images.slice(0, endIndex)];
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="gran-formato">
      <section>
        <div>
          <img className="product-banner pc" src={data.banner.link} alt="Banner de comercio electrónico" />
          <img className="value-banner-mobile mobile" src={data.banner.linkmobile} alt="Banner de comercio electrónico" />
        </div>
        <MarqueeComponent words={data.marqueeWords} />
      </section>

      <section className="mailerbannerproduct">
        <div className="-product-mailer-content">
          <img
            className="product-banner pc"
            src={data.mailerbannerproduct.image}
            alt="Banner de comercio electrónico"
          />
          <div className="product-text-wraper">
            <h1 className="product-contact-title">
              {data.mailerbannerproduct.subtitle}
              <span className="product-highlight">
                {data.mailerbannerproduct.highlight}
              </span>
            </h1>
            <img src="assets/img/home-page/ecommerce/dots-moti.png" alt="dots moti" className="dots-moti" />
            <p
              className="product-description"
              dangerouslySetInnerHTML={{ __html: data.mailerbannerproduct.description }}
            ></p>
            <h3>
              {data.mailerbannerproduct.extratext}
              <strong className='strong'>{data.mailerbannerproduct.extrahighlight}</strong>
            </h3>
            <ContactForm />
          </div>
        </div>
      </section>

      <section className="carousel-section">
        <h2 class="carousel-title">Brand experience
          <img src="assets/img/home-page/ecommerce/logo-dots.png" class="logo+dots" />
        </h2>
        <div className="carousel-container" style={{ display: 'flex', alignItems: 'center' }}>
          <button className="carousel-arrow left" onClick={goToPrevious}>
            &#8249;
          </button>
          <div className="carousel" style={{ display: 'flex', overflow: 'hidden' }}>
            {getVisibleImages().map((image, index) => (
              <div className='img-container-carousel'
                key={index}
                style={{
                  flex: '0 0 33.33%',
                  textAlign: 'center',
                  opacity: index === 1 ? 1 : 0.6,
                  transform: index === 1 ? 'scale(1.1)' : 'scale(1)'
                }}
              >
                <img
                  src={image}
                  alt={`Product ${index}`}
                  style={{ width: '100%', maxHeight: '19rem' }}
                />
              </div>
            ))}
          </div>
          <button className="carousel-arrow right" onClick={goToNext}>
            &#8250;
          </button>
        </div>
      </section>

      <section className="options-product">
        <h3 style={{ textTransform: 'none' }} dangerouslySetInnerHTML={{ __html: data.options.title }}></h3>
        <img src="assets/img/home-page/ecommerce/dots-moti.png" alt="dots moti" className="dots-moti" />
        <h3 dangerouslySetInnerHTML={{ __html: data.options.head }}></h3>
        <div className="options-container">
          <div>
            <p style={{ textTransform: 'none', fontSize: '1.6rem' }} className='options-description' dangerouslySetInnerHTML={{ __html: data.options[1].description }}></p>
          </div>
        </div>
      </section>
      {/* Sección de Preguntas Frecuentes */}
      <section className="faq-section">
        <div className="faq-wraper">
          <div className="faq-title">
            <h3>Preguntas <span>frecuentes</span></h3>
            <img src="assets/img/home-page/ecommerce/dots-moti.png" alt="dots moti" className="dots-moti" />
          </div>
          <div className="faq-contact">
            <p className="faq-tittle">¿Tienes más preguntas?</p>
            <p className="contact-subtitle">No dudes en contactarnos para más información.</p>
            <div className="contact-info">
              <button className="product-contact-button">
                <span className="product-contact-text">{data.contact.tel}</span>
              </button>
              <div className="product-contact-button">
                <span>{data.contact.email}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="faq-container">
          {data.faqData.map((item, index) => (
            <div key={index} className="faq-item">
              <p>{item.question}</p>
              <span className="faq-icon">{item.icon}</span>
            </div>
          ))}
        </div>
      </section>

    </div>
  );
};

export default Brand;
