import React from 'react';

const Numeros = () => {
    const technologies = [
        {
            videoSrc: 'assets/videos/us/nuestos-numeros.mp4',
            mobileVideoSrc: 'assets/videos/us/Nuestos-Numeros-Mobile.webm',
        }
    ];
    const iconsData = [
        {
            id: 1,
            bgColor: "#7ed6c5",
            imgSrc: "../assets/img/us/cartelera.png",
            alt: "Gran formato UV",
            title: "Gran formato UV",
        },
        {
            id: 2,
            bgColor: "#d9c0a7",
            imgSrc: "../assets/img/us/etiqueta.png",
            alt: "Etiquetas y empaques",
            title: "Etiquetas y empaques",
        },
        {
            id: 3,
            bgColor: "#d0151e",
            imgSrc: "../assets/img/us/plaza.png",
            alt: "Visual Merchandising",
            title: "Visual Merchandising",
        },
        {
            id: 4,
            bgColor: "#c97421",
            imgSrc: "../assets/img/us/foto.png",
            alt: "Impresión fotográfica",
            title: "Impresión fotográfica",
        },
    ];

    const statsData = {
        title: "Nuestros números hablan por sí mismos",
        description:
            "Moti Digital fue fundada en 2004 por Alberto García de Quevedo, un ingeniero en electrónica que detectó la transición hacia la impresión digital como una oportunidad clave en la industria gráfica.",
        videoSrc: 'assets/videos/us/videous.mp4',
        videoSrcMobile: 'assets/videos/us/videous-mobile.webm',
        stats: [
            { number: "300 +", label: "Colaboradores", color: "#47172c" },
            { number: "20 +", label: "Años de experiencia", color: "#d0151e" },
            { number: "1,000 +", label: "Clientes satisfechos", color: "#7bc8c0" },
            { number: "2,500 +", label: "Proyectos en América", color: "#f9a825" },
        ],
    };


    return (
        <section className="section dark_section digital_design" id="Tech">
            {technologies.map((tech, index) => (
                <div className="video-tech absolute" style={{ zIndex: -4 }} key={index}>
                    <div className="video-box">
                        {/* Video de escritorio */}
                        <video
                            className="video-desktop"
                            loop
                            autoPlay
                            muted
                            playsInline
                            preload="auto"
                            loading="lazy"
                            width="100%"
                            height="auto"
                        >
                            <source src={tech.videoSrc} type="video/mp4" />
                            Tu navegador no soporta el elemento <code>video</code>.
                            <a href="https://www.google.com/chrome/browser/desktop/index.html" target="_blank">
                                Instalar Google Chrome
                            </a>
                        </video>

                        {/* Video móvil */}
                        <video
                            className="video-mobile"
                            loop
                            autoPlay
                            muted
                            playsInline
                            preload="auto"
                            loading="lazy"
                            width="100%"
                            height="auto"
                        >
                            <source src={tech.mobileVideoSrc} type="video/webm" />
                            Tu navegador no soporta el elemento <code>video</code>.
                            <a href="https://www.google.com/chrome/browser/desktop/index.html" target="_blank">
                                Instalar Google Chrome
                            </a>
                        </video>
                    </div>
                </div>
            ))}
            <div className='partner-wrapper'>
                <div className='partner-main'>
                    {/* Título y descripción */}
                    <div className='left-partner'>
                        <img src="../assets/img/home-page/ecommerce/dots-moti.png" alt="Dots de diseño" class="dots-mailer" />
                        <h2>El único partner de impresión que vas a necesitar.</h2>
                        <p>
                            Expertos en ofrecer infinitas soluciones creativas para que tu marca y
                            producto destaquen en el mercado actual.
                        </p>
                        <button className='boton-partner'>
                            Saber más <img src="../assets/img/us/flecha-correcta.png" alt="flecha" />
                        </button>
                    </div>

                    {/* Iconos de las soluciones */}
                    <div className="right-partner">
                        {iconsData.map((icon) => (
                            <div
                                key={icon.id}
                                className="partner-icon-container"
                                style={{ '--circle-bg-color': icon.bgColor }}
                            >
                                <div className="partner-icon">
                                    <img src={icon.imgSrc} alt={icon.alt} style={{ width: "50px" }} />
                                </div>
                                <h4>{icon.title}</h4>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="stats-section">
                <div className="stats-left">
                    <h2>{statsData.title}</h2>
                    <div className="video-container-us">
                        <video
                            className="video-desktop"
                            loop
                            autoPlay
                            muted
                            playsInline
                            preload="auto"
                            loading="lazy"
                            width="100%"
                            height="auto"
                        >
                            <source src={statsData.videoSrc} type="video/mp4" />
                            Tu navegador no soporta el elemento <code>video</code>.
                            <a
                                href="https://www.google.com/chrome/browser/desktop/index.html"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Instalar Google Chrome
                            </a>
                        </video>
                        <video
                            className="video-mobile"
                            loop
                            autoPlay
                            muted
                            playsInline
                            preload="auto"
                            loading="lazy"
                            width="100%"
                            height="auto"
                        >
                            <source src={statsData.videoSrcMobile} type="video/webm" />
                            Tu navegador no soporta el elemento <code>video</code>.
                            <a
                                href="https://www.google.com/chrome/browser/desktop/index.html"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Instalar Google Chrome
                            </a>
                        </video>
                    </div>
                </div>
                <div className="stats-right">
                    <p>{statsData.description}</p>
                    <div className="stats-grid">
                        {statsData.stats.map((stat, index) => (
                            <div className="stat" key={index}>
                                <strong
                                    className="stat-number"
                                    style={{ color: stat.color }}
                                >
                                    {stat.number}
                                </strong>
                                <span>{stat.label}</span>
                            </div>
                        ))}
                    </div>
                    <button className='boton-partner us-button'>
                        Saber más <img src="../assets/img/us/flecha-correcta.png" alt="flecha" />
                    </button>
                </div>
            </div>
        </section>
    );
};

export default Numeros;
