import React from 'react';

const Isobanner = ({ textos }) => {
  return (
    <div className='iso-banner-main-container'>
      <div className="iso-banner">
        <img src="../assets/img/us/iso.png" alt="" />
        <div className="text-wrapper-iso">
          <h4> contamos con </h4>
          <span>CERTIFICACIÓN ISO 9001</span>
        </div>
        <img src="../assets/img/us/iso.png" alt="" />
      </div>

      <div className='politica'>
      <img src="../assets/img/home-page/ecommerce/dots-moti.png" alt="Dots de diseño" class="dots-calidad"/>
        <h3>Política de <span className="calidad">calidad</span></h3>
        <p>
          En <span className="empresa">Moti</span> nos enfocamos en garantizar la <strong>satisfacción de nuestros clientes</strong> a través de nuestra cultura de 
          <strong> servicio extraordinario</strong> y operación eficaz, trabajando bajo nuestro sistema de gestión integral, cuidando del medio ambiente, la 
          seguridad y salud de nuestros colaboradores y fomentando la <strong>mejora continua</strong> de nuestros procesos.
        </p>
      </div>
    </div>
  );
};

export default Isobanner;
